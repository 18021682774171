import React, { memo, useEffect, useState } from 'react'
import './styles.scss'
import propTypes from 'prop-types'
import cx from 'classnames'
import { useYotpoContext } from '../../../providers/YotpoProvider'
import { refreshYotpoWidgets } from '../../../utils/yotpo'

function YotpoStars({
  sku_product_id,
  name,
  url,
  image_url,
  show_stars_counter = true,
  show_two_lines = false
}) {
  const [loading, setLoading] = useState(true)
  const [yotpoRefreshed, setYotpoRefreshed] = useState(false)
  const { yotpoReady } = useYotpoContext()

  useEffect(() => {
    let timer

    setLoading(true)

    if (!yotpoRefreshed) {
      refreshYotpoWidgets()
      setYotpoRefreshed(true)
    }

    timer = setTimeout(() => {
      setLoading(false)
    }, 1000)

    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [yotpoReady])

  const styleYotpoStars = cx('yotpo-stars', {
    'no-stars-counter': !show_stars_counter,
    'two-lines': show_two_lines,
    'yotpo-stars-loading': loading,
    'yotpo-stars-loaded': !loading
  })

  return (
    <div styleName={styleYotpoStars}>
      {yotpoReady && (
        <div
          className="yotpo bottomLine"
          data-domain="Domain of the shop"
          data-product-id={sku_product_id}
          data-product-models="Products model information"
          data-name={name || 'Product Title'}
          data-url={url || ''}
          data-image-url={image_url || ''}
          data-description="product description"
          data-bread-crumbs="Product categories"
        ></div>
      )}
    </div>
  )
}

YotpoStars.propTypes = {
  sku_product_id: propTypes.string,
  name: propTypes.string,
  url: propTypes.string,
  image_url: propTypes.string,
  show_stars_counter: propTypes.bool
}

const YotpoStartMemo = memo(YotpoStars)

export default YotpoStartMemo
