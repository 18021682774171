import React from 'react'
import { Typography, Box } from '@mui/material'
import propTypes from 'prop-types'
import { onDesktop } from '../../../styles/theme-proven/responsive'
import { SELECT_MULTIPLE_CHECK_BOX_WITH_SIDEBAR_STYLE } from '../../quiz/inputs/select-multiple'
import useWidth from '../../../hooks/useWidth'
import Checkbox from '@mui/material/Checkbox'

function ProvenMultipleQuizSelectorDefault({ onClick, selected, label }) {
  return (
    <Box
      onClick={onClick}
      sx={{
        borderRadius: 1,
        background: selected ? '#F0F7FA' : 'white',
        px: 2,
        py: 1.5,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: { xs: '44px', md: '74px' },
        border: selected ? '1px solid #256376' : '1px solid #ACB1B3',
        '&: hover': {
          [onDesktop]: {
            border: '1px solid #256376'
          }
        }
      }}
    >
      <Typography variant="body2" color={selected ? 'primary' : 'colory.elysian'}>
        {label}
      </Typography>
    </Box>
  )
}

function ProvenMultipleQuizSelectorCheckBoxStyle({ onClick, selected, label }) {
  const width = useWidth()
  const isMobile = width === 'xs' || width === 'sm'

  return (
    <Box
      onClick={onClick}
      sx={{
        borderRadius: 1,
        background: selected ? '#D6EBF2' : '#FFFFFF',
        px: 2,
        py: 1.5,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        textAlign: 'center',
        height: { xs: '56px', md: 'auto' },
        minHeight: { xs: '56px', md: '46px' },
        width: { xs: '100%', md: '46%' },
        border: selected ? '1px solid #D6EBF2' : '1px solid #ACB1B3',
        '& .MuiCheckbox-root': {
          pl: 0
        },
        '&: hover': {
          [onDesktop]: {
            border: '1px solid #ACB1B3'
          }
        }
      }}
    >
      <Checkbox checked={!!selected} label={label} />
      <Typography
        variant="body2"
        color={selected ? '#303C42' : '#5D6469'}
        sx={{
          textAlign: 'left',
          overflowWrap: 'anywhere'
        }}
      >
        {label}
      </Typography>
    </Box>
  )
}

const ProvenMultipleQuizSelector = ({
  label,
  onChange,
  selected,
  value,
  multipleSelectorStyle
}) => {
  switch (multipleSelectorStyle) {
    case SELECT_MULTIPLE_CHECK_BOX_WITH_SIDEBAR_STYLE:
      return (
        <ProvenMultipleQuizSelectorCheckBoxStyle
          onClick={() => onChange({ value, checked: !selected })}
          selected={selected}
          label={label}
        />
      )
    default:
      return (
        <ProvenMultipleQuizSelectorDefault
          onClick={() => onChange({ value, checked: !selected })}
          selected={selected}
          label={label}
        />
      )
  }
}

ProvenMultipleQuizSelector.propTypes = {
  label: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
  selected: propTypes.bool,
  value: propTypes.number,
  multipleSelectorStyle: propTypes.string
}

export default ProvenMultipleQuizSelector
