import React from 'react'
import { Box, SvgIcon } from '@mui/material'
import propTypes from 'prop-types'

function ProvenShape({
  color,
  opacity,
  scale,
  zIndex,
  children,
  baseWidth,
  baseHeight,
  unit,
  viewBox,
  name,
  backgroundColor,
  hoverColor,
  onMouseEnter,
  onMouseLeave,
  justifyContent
}) {
  const svgSx = {
    width: `${baseWidth * scale}${unit}`,
    height: `${baseHeight * scale}${unit}`,
    opacity,
    color: backgroundColor,
    '& path,rect': {
      stroke: color
    },
    zIndex,
    position: 'absolute'
  }
  if (hoverColor)
    svgSx['&:hover'] = {
      color: hoverColor,
      '& path,rect': {
        stroke: hoverColor
      }
    }

  return (
    <Box
      position="relative"
      display="flex"
      justifyContent={justifyContent || 'center'}
      alignItems="center"
      width={children ? 'fit-content' : svgSx.width}
      height={children ? 'fit-content' : svgSx.height}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <SvgIcon
        sx={svgSx}
        component={require(`./svgs/${name}.svg`).ReactComponent}
        viewBox={viewBox}
      ></SvgIcon>
      {children && (
        <Box display="flex" alignItems="center">
          {children}
        </Box>
      )}
    </Box>
  )
}

ProvenShape.defaultProps = {
  scale: 1,
  zIndex: 0,
  unit: 'rem',
  baseWidth: 10.625,
  baseHeight: 12.25,
  backgroundColor: 'transparent'
}

ProvenShape.propTypes = {
  name: propTypes.string.isRequired,
  viewBox: propTypes.string.isRequired,
  baseWidth: propTypes.number,
  baseHeight: propTypes.number,
  unit: propTypes.string,
  scale: propTypes.number,
  color: propTypes.string.isRequired,
  opacity: propTypes.number,
  backgroundColor: propTypes.string,
  hoverColor: propTypes.string,
  children: propTypes.node
}

export default ProvenShape
