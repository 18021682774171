import React, { Component } from 'react'
import './style.scss'

class Spinner extends Component {
  render() {
    return (
      <div styleName="container">
        <div>
          <div styleName="loader" />
        </div>
      </div>
    )
  }
}

export default Spinner
