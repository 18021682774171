import React from 'react'
import { Link } from 'react-router-dom'
import useAuth from 'hooks/use-auth'
import propTypes from 'prop-types'
import useUserTypeAccess from '../../../../hooks/useUserTypeAccess'

function SmartLinkBasedOnUserType({ children, defaultPublicPagePath, onAfterClick }) {
  const { getRedirectionPath } = useUserTypeAccess()

  const { isAuthenticated } = useAuth()
  let forceCongratsPage = false

  const onClick = () => {
    if (onAfterClick) onAfterClick()
  }

  const determineRedirectionPath = defaultPath => {
    const redirectionPathFound = getRedirectionPath(defaultPath)

    //TODO check if forceCongratsPage is still needed and remove it doing the refactors needed
    if (
      redirectionPathFound &&
      ['/account/congratulations', '/account/congratulations/eye'].some(
        p => p === redirectionPathFound
      )
    ) {
      forceCongratsPage = true
    }
    return redirectionPathFound ? redirectionPathFound : defaultPath
  }

  const pathname = !isAuthenticated
    ? defaultPublicPagePath
    : determineRedirectionPath(defaultPublicPagePath)
  const to = { pathname, state: { forceCongratsPage } }
  return (
    <Link to={to} onClick={onClick}>
      {children}
    </Link>
  )
}

SmartLinkBasedOnUserType.propTypes = {
  children: propTypes.node.isRequired,
  defaultPublicPagePath: propTypes.string.isRequired,
  onAfterClick: propTypes.func
}

export default SmartLinkBasedOnUserType
