import React from 'react'
import ProvenIcon from '../proven-icon'
import propTypes from 'prop-types'

function ProvenIconWithRotation({ sx, ...props }) {
  const { iconSize, isRotated } = props
  const provenIconSx = {
    ...sx,
    transform: `rotate(${isRotated ? '180deg' : '0deg'})`,
    transition: 'transform .3s ease-in-out'
  }
  return <ProvenIcon sx={provenIconSx} {...props} size={iconSize} />
}

ProvenIconWithRotation.propTypes = {
  type: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
  isRotated: propTypes.bool.isRequired,
  color: propTypes.string,
  hoverColor: propTypes.string,
  iconSize: propTypes.string
}

export default ProvenIconWithRotation
