import React, { forwardRef } from 'react'
import propTypes from 'prop-types'
import { Container } from '@mui/material'
import { useLocation, useRouteMatch } from 'react-router-dom'

/**
 * This container defines the outer horizontal margins for any page or component
 * based on the style guide and the Proven Theme breakpoints.
 *
 * Make sure it's used inside `<ThemeProvider theme={themeProven}>`
 *
 * @param children
 * @param minHeight
 * @param backgroundColor
 * @returns {JSX.Element}
 */

const ProvenResponsiveContainer = forwardRef(
  (
    {
      children,
      minHeight,
      backgroundColor,
      fitSidePaddingsForDesktopDashboard,
      sx = {},
      notUseDesktopPadding,
      disableLeftPadding = false,
      disableRightPadding = false
    },
    ref
  ) => {
    const location = useLocation()
    const isSystemPdp = useRouteMatch('/account/shop/skin')
    const isInDashboardPage = path => {
      if (!path) {
        console.warn(
          'in isInDashboardPage, location.pathname is undefined. isInDashboardPage will return false'
        )
        return false
      }
      const isCongratsPage = path.indexOf('congratulations') > -1
      const isAccountInPath = path.indexOf('account') > -1
      return isAccountInPath && !isCongratsPage
    }

    const shouldUsePaddingsForDesktopDashboard =
      (fitSidePaddingsForDesktopDashboard && isInDashboardPage(location?.pathname)) ||
      (isSystemPdp && !notUseDesktopPadding)

    const px = shouldUsePaddingsForDesktopDashboard
      ? { xs: 2, md: 8, lg: 7.75, xxl: '10%' }
      : { xs: 2, md: 8, lg: '150px', xxl: '10%' }
    return (
      <Container
        ref={ref}
        maxWidth={false}
        sx={{
          pl: disableLeftPadding ? '0 !important' : px,
          pr: disableRightPadding ? '0 !important' : px,
          ...sx,
          minHeight: minHeight || sx.minHeight || 'unset',
          backgroundColor: backgroundColor || sx.backgroundColor || 'unset'
        }}
      >
        {children}
      </Container>
    )
  }
)

ProvenResponsiveContainer.propTypes = {
  children: propTypes.node.isRequired,
  minHeight: propTypes.string,
  backgroundColor: propTypes.string
}

export default ProvenResponsiveContainer
