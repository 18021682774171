import React from 'react'
import { Alert, AlertTitle, ThemeProvider } from '@mui/material'
import { createProvenTheme } from '../../../../styles/theme-proven'

const themeProven = createProvenTheme()

const NotificationSnackbar = React.forwardRef(
  ({ text, strong, htmlContent, variant, ...props }, ref) => {
    return (
      <ThemeProvider theme={themeProven}>
        <Alert ref={ref} severity={variant} {...props}>
          {strong && <AlertTitle>{strong}</AlertTitle>}
          {text}
          {htmlContent && <div dangerouslySetInnerHTML={{ __html: htmlContent }} />}
        </Alert>
      </ThemeProvider>
    )
  }
)

export default NotificationSnackbar
