import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  GlobalStyles,
  Grid,
  IconButton,
  Stack,
  Typography
} from '@mui/material'
import propTypes from 'prop-types'
import { onDesktop } from '../../../styles/theme-proven/responsive'
import { useHistory } from 'react-router-dom'
import SephoraInStoreModal from './sephora-in-store-modal'
import { createProvenTheme } from '../../../styles/theme-proven'
import { ThemeProvider } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import { withProvenWidth } from '../../../hooks/useProvenWidth'
import { DesktopLottieAnimation, MobileLottieAnimation } from './sephora-in-store-modal/animation'
import { trackEvent } from '../../../utils/analytics'
import { variantSelector } from '../../../utils/selectors'
import { useSelector } from 'react-redux'
import {
  prepareTypographyBody2,
  prepareTypographyH3,
  prepareTypographyH2
} from '../../../styles/theme-proven/typography'
import Drawer from '@mui/material/Drawer'
import MagicLinkForm from '../../pages/login/login-magic-link/magic-link-form'
import withStyles from '@mui/styles/withStyles'
import Login from '../../pages/login'
import { useAuthContext } from '../../../providers/AuthProvider'
import { ACCOUNT_CONGRATULATIONS } from '../../../constants/configs/router'
import { UTM_SEPHORA_BEAUTY_ADVISOR_NAME } from '../sephora-in-store-cover'

const CTA_TEXT_EMAIL_ME = 'START THE QUIZ (3 MINS)'
const TITLE = `<h2>Formulate <em>your</em> custom skincare</h2>`
const DESCRIPTION = 'Let our award-winning AI select the most effective ingredients for your skin.'
const MODAL_EMAIL_TOC = `
    <p>
      By checking this box you consent to receiving marketing and promotional emails
      from PROVEN and any related services provided by PROVEN Group Inc. Email
      consent is not a condition of any purchase. You may unsubscribe at any time
      upon receiving the first email. See our <a href="/faqs" rel="noopener noreferrer">FAQ</a>
      for a step by step process.
      Please view our
      <a href="/terms-and-conditions" rel="noopener noreferrer"
        >Terms &amp; Conditions</a
      >
      and <a href="/privacy-policy" rel="noopener noreferrer">Privacy Policy</a>.
    </p>
`
const MODAL_EMAIL_TITLE = 'When you finish the quiz,  where should we send your results?'
const DATA_CY_PREFIX = 'sephora-in-store-call-to-action'

const themeProven = createProvenTheme()

const getDrawerStyle = (pt = 11, pb = 5, pl = 2, pr = 2) => {
  return {
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flex: 1,
      paddingLeft: themeProven.spacing(pl),
      paddingRight: themeProven.spacing(pr),
      paddingTop: themeProven.spacing(pt),
      paddingBottom: themeProven.spacing(pb),
      width: '100vw',
      minWidth: 360,
      maxWidth: 'unset',
      [onDesktop]: {
        width: '50vw',
        minWidth: 600,
        maxWidth: 'unset',
        paddingLeft: themeProven.spacing(4),
        paddingRight: themeProven.spacing(4),
        paddingTop: themeProven.spacing(4)
      }
    },
    button: {
      right: 20,
      top: 20
    }
  }
}

const DrawerWithStyle = withStyles(getDrawerStyle())(Drawer)

const DrawerWithStyle2 = withStyles(getDrawerStyle(0, 0, 0, 0))(Drawer)

const convertYotpoProductFromBuilder = yotpo_product => {
  return {
    sku_product_id: yotpo_product?.value?.data?.productId,
    name: yotpo_product?.value?.data?.name,
    url: yotpo_product?.value?.data?.url,
    image_url: yotpo_product?.value?.data?.image_path
  }
}

const globalStyles = {
  '@keyframes button-shimmer': {
    from: {
      left: '-100%'
    },
    to: {
      left: '100%'
    }
  },
  '#header_actions': {
    display: 'none'
  },
  '#header_hamburger': {
    position: 'absolute',
    [onDesktop]: {
      position: 'relative'
    }
  },
  '#header_logo': {
    marginLeft: 'auto',
    marginRight: 'auto',

    [onDesktop]: {
      marginLeft: 0,
      marginRight: '16px'
    }
  }
}

/**
 * this wrapper is for re-styled container
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
function ContainerRestyled({ children }) {
  return (
    <Box
      data-testid="container-restyled-testid"
      sx={{
        p: {
          ...prepareTypographyBody2(themeProven),
          // lineHeight: '21px',
          marginBlockStart: '0px !important'
        },
        h2: {
          '@media (max-width: 380px)': {
            ...prepareTypographyH3(themeProven)
          },
          ...prepareTypographyH2(themeProven),
          marginBlockStart: 0,
          marginBlockEnd: 0,
          textTransform: 'none',
          [onDesktop]: {
            fontSize: { md: 42, lg: 60 },
            lineHeight: '66px'
          }
        },
        em: {
          color: 'primary.main'
        }
      }}
    >
      {children}
    </Box>
  )
}

function SephoraInStoreCallToAction({
  buttonTextEmailMe = CTA_TEXT_EMAIL_ME,
  yotpo_product,
  title = TITLE,
  description = DESCRIPTION,
  storeId,
  lottieFileMobile,
  lottieFileDesktop,
  modalEmailTitle = MODAL_EMAIL_TITLE,
  modalEmailToc = MODAL_EMAIL_TOC,
  width
}) {
  const variant = useSelector(variantSelector)
  const [isOpen, setIsOpen] = useState(false)
  const [resetPasswordOpen, setResetPasswordOpen] = useState(false)
  const [passwordResetRequired, setPasswordResetRequired] = useState(false)
  const [email, setEmail] = useState('')
  const history = useHistory()
  const yotpoProduct = convertYotpoProductFromBuilder(yotpo_product)
  const isMobile = width === `xs` || width === `sm`
  const { isAuthenticated } = useAuthContext()

  useEffect(() => {
    if (isAuthenticated) {
      // redirect to congratulations page
      history.push(ACCOUNT_CONGRATULATIONS)
    }
  }, [isAuthenticated])

  const handleCTAStartQuiz = e => {
    if (e) {
      e.stopPropagation()
      e.preventDefault()
    }

    // track CTA click
    trackEvent('start_the_quiz_cta', {
      CTA: `${buttonTextEmailMe || CTA_TEXT_EMAIL_ME}`,
      variant: variant
    })

    // open the modal
    setIsOpen(true)
  }

  const handleRedirectToQuiz = (email, baName) => {
    // need to pass email and baName to quiz
    const urlQuizPage = `quiz?sephoraStoreId=${storeId}&email=${encodeURIComponent(
      email
    )}&${UTM_SEPHORA_BEAUTY_ADVISOR_NAME}=${encodeURIComponent(baName)}`
    history.push(urlQuizPage)
  }

  const handleResetPasswordClose = () => {
    setResetPasswordOpen(false)
    setPasswordResetRequired(false)
  }

  const toggleMagicLinkRequest = e => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    setResetPasswordOpen(false)
  }

  const handleClose = e => {
    if (e) {
      e.stopPropagation()
      e.preventDefault()
    }

    setIsOpen(false)
  }

  const handleOnComplete = (
    emailFromModal,
    baNameFromModal,
    options = {
      redirect: true,
      resetPasswordOpen: false,
      passwordResetRequired: false
    }
  ) => {
    handleClose()
    setEmail(emailFromModal)
    setResetPasswordOpen(options?.resetPasswordOpen)
    setPasswordResetRequired(options?.passwordResetRequired)
    if (options?.redirect) {
      handleRedirectToQuiz(emailFromModal, baNameFromModal)
    }
  }

  return (
    <ThemeProvider theme={themeProven}>
      <ContainerRestyled>
        <GlobalStyles styles={globalStyles} />
        <Grid
          data-cy={`${DATA_CY_PREFIX}-section-container-grid`}
          container
          sx={{
            display: 'flex',
            alignContent: 'flex-end',
            textAlign: 'center',
            [onDesktop]: {
              justifyContent: 'space-between',
              alignContent: 'center',
              textAlign: 'left',
              pl: { md: 0, lg: 0 },
              pr: { md: 8, lg: 0 },
              pb: 0
            }
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            lg={5}
            zIndex={2}
            sx={{
              [onDesktop]: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }
            }}
          >
            <Box
              data-cy={`${DATA_CY_PREFIX}-section-title`}
              sx={{
                // position: "relative",
                mx: { xs: 0, md: 0 }
                // my: { xs: 4 }
              }}
            >
              <Box
                sx={{
                  mx: 2,
                  [onDesktop]: {
                    mx: 0
                  }
                }}
              >
                <Box
                  sx={{
                    mx: 'auto',
                    mb: 4,
                    textAlign: 'center',
                    maxWidth: '350px',
                    [onDesktop]: {
                      maxWidth: { md: 360, lg: 'unset' },
                      mb: { md: 5 },
                      mx: { md: 0, lg: 0 },
                      textAlign: 'left',
                      position: 'relative'
                    }
                  }}
                >
                  <span dangerouslySetInnerHTML={{ __html: title || TITLE }}></span>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={7} zIndex={1}>
            <Box
              data-cy={`${DATA_CY_PREFIX}-section-animation`}
              sx={{
                width: '100%',
                ml: { xs: 0, md: '0', lg: '150px', xxl: '10%' },
                mr: { xs: 0, md: '-125px', lg: '-150px' }
              }}
            >
              {isMobile ? (
                <MobileLottieAnimation lottieFileMobile={lottieFileMobile} />
              ) : (
                <DesktopLottieAnimation lottieFileDesktop={lottieFileDesktop} />
              )}
            </Box>

            <Typography
              variant="body1"
              color="gray.elysian"
              sx={{
                mx: 'auto',
                maxWidth: '380px',
                px: 1,
                mt: 3,
                mb: 2,
                [onDesktop]: {
                  display: 'none'
                }
              }}
            >
              {description || DESCRIPTION}
            </Typography>
          </Grid>
        </Grid>

        {/* buttons container */}
        <Box
          position="fixed"
          sx={{
            width: '100%',
            bottom: 0,
            left: 0,
            px: 2,
            py: 1.5,
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            backdropFilter: 'blur(18px)',
            zIndex: 3,
            [onDesktop]: {
              display: 'flex',
              flexDirection: 'columns',
              justifyContent: 'space-between',
              px: { md: '64px', lg: '150px' },
              gap: 1
            }
          }}
        >
          <Typography
            variant="body1"
            sx={{
              display: 'none',
              [onDesktop]: {
                display: 'flex',
                width: '45%'
              }
            }}
          >
            {description || DESCRIPTION}
          </Typography>

          <Stack
            data-testid={`${DATA_CY_PREFIX}-button-container`}
            sx={{
              [onDesktop]: {
                flexDirection: 'row',
                gap: 2,
                width: '55%'
              }
            }}
          >
            <Button
              onClick={handleCTAStartQuiz}
              data-cy={`${DATA_CY_PREFIX}-email-me-my-quiz`}
              data-testid={`${DATA_CY_PREFIX}-email-me-my-quiz`}
              variant="contained"
              color="secondary"
              fullWidth
              type="submit"
              sx={{
                overflow: 'hidden',

                '&::before': {
                  animation: 'button-shimmer 2s linear infinite',
                  background:
                    'linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 22%, rgba(255, 255, 255, 0.45) 25%, rgba(255, 255, 255, 0.4) 22%, rgba(255, 255, 255, 0) 50%)',
                  content: '""',
                  minWidth: '15.625rem',
                  position: 'absolute',
                  top: 0,
                  bottom: 0
                }
              }}
            >
              {buttonTextEmailMe || CTA_TEXT_EMAIL_ME}
            </Button>
          </Stack>
        </Box>

        <Dialog
          data-testid={`${DATA_CY_PREFIX}-dialog`}
          fullScreen={isMobile}
          fullWidth={!isMobile}
          maxWidth={isMobile ? false : 'sm'}
          open={isOpen}
          onClose={handleClose}
          sx={{
            '.MuiDialog-paperFullWidth': {
              [onDesktop]: {
                maxWidth: '550px'
              }
            }
          }}
        >
          <Box sx={{ position: 'absolute', top: 20, right: 15, zIndex: 1 }}>
            <IconButton aria-label="close" onClick={handleClose} size="large">
              <CloseIcon />
            </IconButton>
          </Box>
          <SephoraInStoreModal
            onComplete={handleOnComplete}
            yotpoProduct={yotpoProduct}
            storeId={storeId}
            modalEmailTitle={modalEmailTitle || MODAL_EMAIL_TITLE}
            modalEmailToc={modalEmailToc || MODAL_EMAIL_TOC}
          />
        </Dialog>
      </ContainerRestyled>
      <DrawerWithStyle anchor="right" open={resetPasswordOpen} onClose={handleResetPasswordClose}>
        <MagicLinkForm
          email={email}
          isMobile={isMobile}
          isMagicLinkSent={true}
          onToggleEmailPassword={toggleMagicLinkRequest}
          handleResetPasswordClose={handleResetPasswordClose}
        />
      </DrawerWithStyle>
      <DrawerWithStyle2
        anchor="right"
        open={passwordResetRequired}
        onClose={handleResetPasswordClose}
      >
        <Login
          onClose={handleResetPasswordClose}
          resetPassword={!passwordResetRequired}
          passwordResetRequired={passwordResetRequired}
          isFromQuiz={true}
        />
      </DrawerWithStyle2>
    </ThemeProvider>
  )
}

SephoraInStoreCallToAction.propTypes = {
  title: propTypes.string,
  subTitle: propTypes.string,
  buttonTextEmailMe: propTypes.string,
  lottieFileMobile: propTypes.string,
  lottieFileDesktop: propTypes.string
}

export default withProvenWidth(SephoraInStoreCallToAction)
