import React, { useContext } from 'react'
import { EYE_CREAM_PRODUCT, SERUM_PRODUCT, SYSTEM_PRODUCT } from '../../../../../constants/products'
import { BASE_PRODUCT_ID_AND_YOTPO_PRODUCT_ID_MAP } from '../../../../../constants/yotpo'
import propTypes from 'prop-types'
import './styles.scss'
import { IntentAndFunnelsContext } from '../../../../../providers/IntentAndFunnelsProvider'
import {
  FUNNEL_SUBSCRIPTION_ACTIVE,
  FUNNEL_NOT_STARTED,
  FUNNEL_QUIZ_COMPLETED,
  FUNNEL_SUBSCRIPTION_INACTIVE
} from '../../../../../constants/funnels'
import useAuth from 'hooks/use-auth'
import { Box, Button, Stack, Typography } from '@mui/material'
import ProvenIcon from '../../../proven-icon'
import { onDesktop } from '../../../../../styles/theme-proven/responsive'

function ProductQuizMenu({
  id,
  product,
  title,
  description,
  onStartQuiz,
  onCongratsPage,
  onShopPage,
  onSubscriptionsManagement,
  onUpdateQuiz,
  hideButtonWithOnCongratsPageAction,
  hideYotpo,
  image,
  imageBackgroundColor,
  small,
  onClose
}) {
  const { skinFunnel, eyeFunnel, serumFunnel } = useContext(IntentAndFunnelsContext)
  const funnelByProduct = {
    [SYSTEM_PRODUCT]: skinFunnel,
    [EYE_CREAM_PRODUCT]: eyeFunnel,
    [SERUM_PRODUCT]: serumFunnel
  }
  const { isAuthenticated } = useAuth()

  const isThereOtherSubscription = () =>
    Object.keys(funnelByProduct).some(
      p => p !== product && funnelByProduct[p] === FUNNEL_SUBSCRIPTION_ACTIVE
    )

  const funnel = funnelByProduct[product]
  let buttons = null
  if (!isAuthenticated || funnel === FUNNEL_NOT_STARTED) {
    buttons = (
      <Button variant="outlined" color="primary" onClick={onStartQuiz}>
        Start Quiz
      </Button>
    )
  } else if (funnel === FUNNEL_QUIZ_COMPLETED) {
    const isOtherSubscription = isThereOtherSubscription()
    const isReviewMyFormulaShown = isOtherSubscription || !hideButtonWithOnCongratsPageAction
    buttons = (
      <>
        {isReviewMyFormulaShown && (
          <Button
            variant="outlined"
            color="primary"
            onClick={isOtherSubscription ? onShopPage : onCongratsPage}
          >
            Review My Formula
          </Button>
        )}
        <Button
          variant="text"
          color="primary"
          onClick={onStartQuiz}
          size="medium"
          endIcon={<ProvenIcon type="system" name="arrow-right" />}
        >
          Retake Quiz
        </Button>
      </>
    )
  } else if (funnel === FUNNEL_SUBSCRIPTION_ACTIVE) {
    buttons = (
      <Button variant="outlined" color="primary" onClick={onUpdateQuiz}>
        Update Quiz
      </Button>
    )
  } else if (funnel === FUNNEL_SUBSCRIPTION_INACTIVE) {
    buttons = (
      <>
        <Button variant="outlined" color="primary" onClick={onSubscriptionsManagement}>
          Reactivate
        </Button>
        <Button
          variant="text"
          color="primary"
          onClick={onStartQuiz}
          size="medium"
          endIcon={<ProvenIcon type="system" name="arrow-right" />}
        >
          Retake Quiz
        </Button>
      </>
    )
  }

  const getYotpoIdFromProductId = productId => BASE_PRODUCT_ID_AND_YOTPO_PRODUCT_ID_MAP[productId]

  /*
          sx={{
          ml: image ? 7 : 0
        }}
  * */
  return (
    <Stack direction="row" id={id}>
      {image && (
        <Box
          sx={{
            width: 'fit-content',
            height: 'fit-content',
            mr: 2,
            backgroundColor: imageBackgroundColor ? imageBackgroundColor : undefined
          }}
        >
          {image}
        </Box>
      )}
      <Stack>
        <Typography
          sx={{
            paddingBottom: 2,
            textTransform: 'capitalize',
            [onDesktop]: { textTransform: 'uppercase' }
          }}
          variant="title2"
          color="gray.elysian"
        >
          {title}
        </Typography>
        <Box sx={{ marginRight: '14%' }}>
          <Typography paragraph={true} sx={{ mb: 1 }} variant="footnoteLight">
            {description}
          </Typography>
        </Box>
        {!hideYotpo && (
          <Box sx={{ mb: 2 }} onClick={onClose}>
            <div styleName="yotpo-stars">
              <div
                className="yotpo bottomLine"
                data-product-id={getYotpoIdFromProductId(product)}
                data-url={''}
              ></div>
            </div>
          </Box>
        )}
        <Stack
          direction="row"
          sx={{
            width: small ? 'auto' : 'max-content',
            flexWrap: small ? 'wrap' : 'nowrap',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: { xs: '16px', md: '8px' }
          }}
        >
          {buttons}
        </Stack>
      </Stack>
    </Stack>
  )
}

ProductQuizMenu.defaultProps = {
  small: false,
  hideYotpo: false,
  hideButtonWithOnCongratsPageAction: false
}

ProductQuizMenu.propTypes = {
  title: propTypes.node.isRequired,
  description: propTypes.node.isRequired,
  image: propTypes.node,
  imageBackgroundColor: propTypes.string,
  small: propTypes.bool,
  hideYotpo: propTypes.bool,
  hideButtonWithOnCongratsPageAction: propTypes.bool,
  product: propTypes.oneOf([SYSTEM_PRODUCT, EYE_CREAM_PRODUCT, SERUM_PRODUCT]).isRequired,
  onStartQuiz: propTypes.func.isRequired,
  onCongratsPage: propTypes.func.isRequired,
  onShopPage: propTypes.func.isRequired,
  onSubscriptionsManagement: propTypes.func.isRequired,
  onUpdateQuiz: propTypes.func.isRequired,
  onClose: propTypes.func
}
export default ProductQuizMenu
