import React, { useEffect, useState } from 'react'
import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import propTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'
import { CDN_IMG } from '../../../constants/endpoints'
import { withProvenWidth } from '../../../hooks/useProvenWidth'
import ProvenPage from '../proven-page'
import ProvenLogo from '../proven-logo'
import { LOGO_SEPHORA, LOGO_SKIN_GENOME_QUIZ } from '../../../constants/logos'
import ProvenBottomDrawerStatic from '../proven-bottom-drawer-static'
import { onDesktop } from '../../../styles/theme-proven/responsive'
import { trackEvent } from '../../../utils/analytics'
import { useSelector } from 'react-redux'
import { variantSelector } from '../../../utils/selectors'
import { getTokensFromSearch } from '../../../utils/helpers'

const DATA_CY_PREFIX = 'sephora-in-store-cover'
const CTA_BUTTON_TXT = 'START'
const TITLE = 'Let’s get started!'
const SUBTITLE = 'Only 3 minutes to your personalized skincare formula.'
const BEAUTY_ADVISOR_OPTIONAL = 'OPTIONAL'
const BEAUTY_ADVISOR_DESCRIPTION = 'If you were helped by a Beauty Advisor, enter their name below.'
const BEAUTY_ADVISOR_INPUT_PLACEHOLDER = 'Beauty Advisor Name (Optional)'
export const UTM_SEPHORA_BEAUTY_ADVISOR_NAME = 'sephoraAdvisorName'
const UTM_EMAIL = 'email'

function SephoraInStoreCover({
  width,
  title = TITLE,
  subTitle = SUBTITLE,
  buttonText = CTA_BUTTON_TXT,
  beautyAdvisorOptional = BEAUTY_ADVISOR_OPTIONAL,
  beautyAdvisorDescription = BEAUTY_ADVISOR_DESCRIPTION,
  beautyAdvisorInputPlaceholder = BEAUTY_ADVISOR_INPUT_PLACEHOLDER
}) {
  const [showBaNameOptional, setShowBaNameOptional] = useState(false)
  const [baName, setBaName] = useState('')
  const isMobile = width === `xs` || width === `sm`
  const isDesktop = !isMobile
  const history = useHistory()
  const location = useLocation()
  const variant = useSelector(variantSelector)

  const bg = isMobile
    ? `url('${CDN_IMG}quiz/bg-ingredient-texture.png') no-repeat fixed 50% 0 / 200%, linear-gradient(219.99deg, #FFFFFF 41.68%, #D6EBF2 86.99%)`
    : `url('${CDN_IMG}quiz/bg-ingredient-texture.png') no-repeat fixed 350px -350px / 1500px, linear-gradient(219.99deg, #FFFFFF 41.68%, #D6EBF2 86.99%)`

  useEffect(() => {
    if (!location?.search?.includes(UTM_EMAIL)) {
      setShowBaNameOptional(true)
    }
  }, [location])

  const handleBaNameChange = e => {
    const myBaName = e.currentTarget.value
    setBaName(myBaName)
  }

  const encodeUtm = utm => {
    try {
      if (utm) {
        const utmToken = utm?.split('=')
        const utmKey = utmToken[0]
        const utmValue = encodeURIComponent(utmToken[1])
        return `${utmKey}=${utmValue}`
      }
      return ''
    } catch (e) {
      return ''
    }
  }

  const getEncodedSearch = search => {
    try {
      const tokens = getTokensFromSearch(search)
      const searchEncoded = tokens?.reduce((acc, curr) => {
        let newUtm = ''
        if (curr?.startsWith(UTM_EMAIL) || curr?.startsWith(UTM_SEPHORA_BEAUTY_ADVISOR_NAME)) {
          newUtm = encodeUtm(curr)
        } else {
          newUtm = curr
        }

        return `${acc}&${newUtm}`
      }, '')

      return searchEncoded
    } catch (e) {
      return ''
    }
  }

  const handleStart = () => {
    let search = ''
    const hash = location?.hash
    // check if the BA name is enter
    if (showBaNameOptional) {
      const utm = `${UTM_SEPHORA_BEAUTY_ADVISOR_NAME}=${baName}`
      search = location?.search
        ? `${location?.search}&${utm}`
        : `${UTM_SEPHORA_BEAUTY_ADVISOR_NAME}=${baName}`
    } else {
      search = location?.search
    }

    // track CTA click
    trackEvent('start', { CTA: buttonText, variant })

    console.log('handleStart `${search}${hash}`', `${search}${hash}`)
    const searchFormatted = getEncodedSearch(`${search}${hash}`)
    console.log('handleStart searchFormatted', searchFormatted)

    history.push({
      pathname: '/quiz',
      search: `${searchFormatted}`
    })
  }

  const buttonsContainer = (
    <Box
      display="flex"
      flexDirection="column"
      gap={2.5}
      data-testid={`${DATA_CY_PREFIX}-mobile-drawer`}
    >
      <Button
        onClick={handleStart}
        data-cy={`${DATA_CY_PREFIX}-email-me-my-quiz`}
        data-testid={`${DATA_CY_PREFIX}-email-me-my-quiz`}
        variant="contained"
        color="secondary"
        fullWidth
        sx={{
          mb: 0,
          [onDesktop]: {
            mb: 2
          }
        }}
        type="submit"
      >
        {buttonText}
      </Button>
    </Box>
  )

  return (
    <ProvenPage background={bg}>
      <Box
        sx={{
          py: { xs: 2.5, md: 4 },
          display: { xs: 'flex', md: 'none' },
          justifyContent: 'center',
          maxWidth: { xxl: '1140px' },
          mx: { xxl: 'auto' }
        }}
      >
        <ProvenLogo variant={LOGO_SEPHORA} color="primary" />
      </Box>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          py: { xs: 0, md: 4 },
          minHeight: { xs: 'calc(100vh - 140px)', md: '100vh' },
          maxWidth: { xxl: '1140px' },
          mx: { xxl: 'auto' },
          mt: { xs: 0, xxl: 15 }
        }}
      >
        <Box
          sx={{
            svg: {
              width: '150px!important',
              [onDesktop]: {
                width: '169px!important'
              }
            }
          }}
        >
          <ProvenLogo variant={LOGO_SKIN_GENOME_QUIZ} />
        </Box>
        <Typography
          variant="h2"
          sx={{
            mx: 'auto',
            mb: 2,
            textAlign: 'center',
            maxWidth: '365px',
            [onDesktop]: {
              maxWidth: { md: 472, lg: 'unset' },
              mb: { md: 2 },
              position: 'relative'
            }
          }}
        >
          {title}
        </Typography>
        <Typography
          variant={isMobile ? 'body1' : 'body2'}
          sx={{
            mx: 'auto',
            textAlign: 'center',
            maxWidth: '293px',
            [onDesktop]: {
              maxWidth: { md: 424, lg: 'unset' }
            }
          }}
        >
          {subTitle}
        </Typography>
        {showBaNameOptional && (
          <Box
            sx={{
              mt: 4,
              px: 2,
              py: 1.5,
              maxWidth: 343,
              border: 1,
              borderRadius: 1,
              borderColor: 'gray.lightBlue',
              backgroundColor: 'gray.lightBlue',
              boxShadow: 3,
              [onDesktop]: {
                maxWidth: 472
              }
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: '10px',
                mb: '2px',
                [onDesktop]: {
                  fontSize: '12px'
                }
              }}
            >
              {beautyAdvisorOptional}
            </Typography>
            <Typography
              variant="title2"
              component="div"
              sx={{
                [onDesktop]: {
                  maxWidth: 408
                }
              }}
            >
              {beautyAdvisorDescription}
            </Typography>
            <TextField
              fullWidth
              onChange={handleBaNameChange}
              name="baName"
              placeholder={beautyAdvisorInputPlaceholder}
              value={baName}
              sx={{
                mt: 1
              }}
            />
          </Box>
        )}
        {isDesktop && (
          <Box
            sx={{
              [onDesktop]: {
                mt: 7.25,
                width: '100%',
                maxWidth: 267
              }
            }}
          >
            {buttonsContainer}
          </Box>
        )}
      </Stack>
      {isMobile && <ProvenBottomDrawerStatic>{buttonsContainer}</ProvenBottomDrawerStatic>}
    </ProvenPage>
  )
}

SephoraInStoreCover.propTypes = {
  title: propTypes.string,
  subTitle: propTypes.string,
  buttonText: propTypes.string,
  beautyAdvisorOptional: propTypes.string,
  beautyAdvisorDescription: propTypes.string,
  beautyAdvisorInputPlaceholder: propTypes.string
}

export default withProvenWidth(SephoraInStoreCover)
