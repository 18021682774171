import Lottie from 'react-lottie-player'
import React, { useEffect, useState } from 'react'
// const animationViewPort = 1.218;

export function MobileLottieAnimation({ lottieFileMobile, onComplete }) {
  const [animationData, setAnimationData] = useState()
  // const mobileAnimationWidth = window.innerWidth + 160;

  const fetchJson = () => {
    fetch(lottieFileMobile)
      .then(response => {
        return response.json()
      })
      .then(data => {
        setAnimationData(data)
      })
      .catch(e => {
        console.log(`MobileLottieAnimation ${e?.message}`)
      })
  }
  useEffect(() => {
    fetchJson()
  }, [lottieFileMobile])

  if (!animationData) return <></>

  return (
    <>
      <Lottie
        play
        animationData={animationData}
        className="mobile-animation"
        onComplete={onComplete}
        onLoopComplete={onComplete}
        rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
        style={{}}
      />
    </>
  )
}

export function DesktopLottieAnimation({ lottieFileDesktop, onComplete }) {
  const [animationData, setAnimationData] = useState()

  const fetchJson = () => {
    fetch(lottieFileDesktop)
      .then(response => {
        return response.json()
      })
      .then(data => {
        setAnimationData(data)
      })
      .catch(e => {
        console.log(`DesktopLottieAnimation ${e?.message}`)
      })
  }
  useEffect(() => {
    fetchJson()
  }, [lottieFileDesktop])

  if (!animationData) return <></>

  return (
    <>
      <Lottie
        play
        animationData={animationData}
        style={{
          maxWidth: '100vh'
        }}
        onComplete={onComplete}
        onLoopComplete={onComplete}
      />
    </>
  )
}
