import React, { useState } from 'react'
import { Box, Stack } from '@mui/material'
import { CDN_IMG } from '../../../../constants/endpoints'
import YotpoStars from '../../yotpo-stars'
import EnterYourEmailContainer from './enter-your-email-container'
import EnterBaNameContainer from './enter-ba-name-container'
import { onDesktop } from '../../../../styles/theme-proven/responsive'

const DATA_CY_PREFIX = 'sephora-in-store-modal'

function SephoraInStoreModal({
  onComplete,
  yotpoProduct,
  storeId,
  modalEmailTitle,
  modalEmailToc
}) {
  const [showEnterYourEmail, setEnterYourEmail] = useState(true)
  const [showBaName, setShowBaName] = useState(false)
  const [email, setEmail] = useState('')
  const [existingUserStatus, setExistingUserStatus] = useState('')

  const onSubmitEmail = (emailOutside, existingUserStatusOutside) => {
    setEnterYourEmail(false)
    setShowBaName(true)
    setEmail(emailOutside)
    setExistingUserStatus(existingUserStatusOutside)
  }

  /**
   * this function is call when normal flow (no magic link is trigger)
   * for example when a empty user is created and do not exist in db
   * @param baNameOutside
   */
  const onCompleteBaName = baNameOutside => {
    onComplete(email, baNameOutside, {
      redirect: true
    })
    setShowBaName(false)
    setEnterYourEmail(true)
  }

  /**
   * this functino is call when magicLink is trigger
   * @param emailFromModal
   * @param baName
   * @param resetPasswordOpen
   * @param passwordResetRequired
   */
  const onCompleteEmailByMagicLink = (
    emailFromModal,
    baName,
    resetPasswordOpen = false,
    passwordResetRequired = false
  ) => {
    onComplete(emailFromModal, baName, {
      redirect: false,
      resetPasswordOpen,
      passwordResetRequired
    })
    setShowBaName(false)
    setEnterYourEmail(false)
  }

  return (
    <Box
      data-cy={`${DATA_CY_PREFIX}-section`}
      sx={{
        position: 'relative',
        [onDesktop]: {
          height: { md: '75vh' }
        }
      }}
    >
      <Stack
        gap={1}
        sx={{
          position: 'relative'
        }}
      >
        {yotpoProduct && (
          <Box
            data-testid={`${DATA_CY_PREFIX}-yotpo-starts-container`}
            sx={{
              position: 'absolute',
              top: 9,
              left: 14
            }}
          >
            <YotpoStars {...yotpoProduct} show_two_lines />
          </Box>
        )}
        <img src={`${CDN_IMG}sephora/instore-popup-bg-img.jpg`} alt="Proven" />
        {showEnterYourEmail && (
          <EnterYourEmailContainer
            storeId={storeId}
            onSubmit={onSubmitEmail}
            title={modalEmailTitle}
            toc={modalEmailToc}
            onClose={onCompleteEmailByMagicLink}
          />
        )}
        {showBaName && (
          <EnterBaNameContainer
            email={email}
            storeId={storeId}
            existingUserStatus={existingUserStatus}
            onCompleteEmptyUser={onCompleteBaName}
            onCompleteWithMagicLink={onCompleteEmailByMagicLink}
          />
        )}
      </Stack>
    </Box>
  )
}

SephoraInStoreModal.propTypes = {}

export default SephoraInStoreModal
