import React from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import { BUILDER_CDN } from '../../../constants/endpoints'
import useMediaQuery from '@mui/material/useMediaQuery'
import Benefits from './benefits'
import propTypes from 'prop-types'
import { addSystemToCart, openCart } from '../../../actions/cart.actions'
import { connect } from 'react-redux'
import PricingWithProofing from '../pricing/pricing-with-proofing'
import ProvenButtonWithIntent from '../proven-button-with-intent'
import { Image } from 'components/builder/blocks/shared/Image'
import useAuth from 'hooks/use-auth'

const CTA_TEXT = 'Subscribe'
const DATA_CY_PREFIX = 'subscribe'
const DATA_CY_PREFIX_DESKTOP = `${DATA_CY_PREFIX}-desktop`
const DATA_CY_PREFIX_MOBILE = `${DATA_CY_PREFIX}-mobile`

/**
 *
 * @param addSystemToCart
 * @param openCart
 * @returns {JSX.Element|null}
 * @constructor
 */
function SubscribeAndThriveSystem({
  buttonText = CTA_TEXT,
  addPriceInButtonWhenIsAuthenticated,
  addSystemToCart,
  openCart
}) {
  const { isAuthenticated } = useAuth()
  const isLgUp = useMediaQuery(theme => theme.breakpoints.up('lg'))

  const isSmDown = !isLgUp

  const handleActivateMyPlan = (e, cta) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    addSystemToCart(cta)
    openCart()
  }

  return (
    <Box
      sx={{
        position: 'relative',
        backgroundColor: ['unset']
      }}
    >
      <Grid
        container
        justifyContent="center"
        sx={{
          pt: [6, 6],
          pb: [6, 6, 6, 12, 22],
          position: 'relative',
          zIndex: 1,
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            position: 'relative',
            left: [14, 14, 14, 26],
            marginBottom: ['-50px', '-50px', '-64px', '-100px'],
            height: [195, 223, 223, 321],
            width: [280, 280, 320, 460]
          }}
        >
          <Image
            lazy
            backgroundSize="contain"
            backgroundPosition="bottom"
            image={`${BUILDER_CDN}9be7b3a343374be9b104de2868ae6f69%2F88e687edbc924c348dbff7f1e5852b06`}
            aspectRatio={1.433}
          />
        </Box>
        <Typography
          variant="h2"
          sx={{
            mb: [2, 2, 4],
            textAlign: 'center'
          }}
        >
          Subscribe {isSmDown && <br />}& Thrive
        </Typography>
        <Box
          sx={{
            width: '100%'
          }}
        >
          <Benefits />
        </Box>
        {isAuthenticated ? ( // if authenticated -> place System to cart; if not -> follow user's intent
          <Button
            variant="contained"
            color="secondary"
            sx={{
              mt: [4, 4],
              mr: 'auto',
              ml: 'auto'
            }}
            onClick={e => {
              handleActivateMyPlan(e, buttonText)
            }}
            data-cy={
              isLgUp
                ? `${DATA_CY_PREFIX_DESKTOP}-add-to-cart-button`
                : `${DATA_CY_PREFIX_MOBILE}-add-to-cart-button`
            }
          >
            <Typography
              variant="button"
              mr={addPriceInButtonWhenIsAuthenticated ? 0.5 : 0}
            >{`${buttonText}${addPriceInButtonWhenIsAuthenticated ? ' - ' : ''}`}</Typography>
            {addPriceInButtonWhenIsAuthenticated && <PricingWithProofing variant="button" />}
          </Button>
        ) : (
          <Box
            sx={{
              mt: [4, 4],
              mr: 'auto',
              ml: 'auto'
            }}
          >
            <ProvenButtonWithIntent
              variant="contained"
              color="secondary"
              text={buttonText}
              data-cy={`${DATA_CY_PREFIX}-start-skin-quiz-button`}
            />
          </Box>
        )}

        <Box
          sx={{
            display: 'flex',
            position: 'relative',
            marginTop: '8px',
            height: [237, 237, 296, 392],
            width: [320, 320, 400, 530],
            left: [-35, -35, -35, -58]
          }}
        >
          <Image
            lazy
            backgroundSize="contain"
            backgroundPosition="top"
            image={`${BUILDER_CDN}9be7b3a343374be9b104de2868ae6f69%2F1549fa980bfd493fa2513c61bcef7168`}
            aspectRatio={1.351}
          />
        </Box>
      </Grid>
    </Box>
  )
}

SubscribeAndThriveSystem.propTypes = {
  addPriceInButtonWhenIsAuthenticated: propTypes.bool,
  addSystemToCart: propTypes.func,
  openCart: propTypes.func,
  buttonText: propTypes.string
}

const mapDispatchToProps = dispatch => ({
  addSystemToCart: args => dispatch(addSystemToCart(args)),
  openCart: () => dispatch(openCart())
})

export default connect(null, mapDispatchToProps)(SubscribeAndThriveSystem)
