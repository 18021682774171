import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import propTypes from 'prop-types'
import { EYE_CREAM_PRODUCT, SERUM_PRODUCT, SYSTEM_PRODUCT } from '../../../../constants/products'
import { closeMobileMenu } from '../../../../actions/app.actions'
import ProductQuizMenu from './product-quiz-menu'
import { Box, Divider, Stack } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { createProvenTheme } from '../../../../styles/theme-proven'
import ProvenIcon from '../../proven-icon'
import { CDN_IMG } from '../../../../constants/endpoints'
import { appEditionSelector } from '../../../../utils/selectors'
import { APP_EDITION_SEPHORA } from '../../../../constants/constants'

const themeProven = createProvenTheme()
const spacing = themeProven.spacing

function SkinQuizMenu({ showProductDivider, variant, ...props }) {
  const imageSx = {
    width: { xs: spacing(16.75), md: spacing(22.5) },
    height: '100%',
    pt: { xs: spacing(1.81), md: spacing(2.5) },
    pr: { xs: spacing(1.29), md: spacing(1.71) },
    pb: { xs: spacing(0.8), md: spacing(1) },
    pl: { xs: spacing(2), md: spacing(2.75) }
  }

  const appEdition = useSelector(appEditionSelector)
  const history = useHistory()
  const dispatch = useDispatch()
  const { onPageChange } = props
  const location = useLocation()
  const isInSkinCongratsPage = !!(
    location?.pathname && location?.pathname.match('/account/congratulations(/)?$')
  )
  const isInEyeCongratsPage = !!(
    location?.pathname && location?.pathname.match('/account/congratulations/eye(/)?$')
  )
  const isInSerumCongratsPage = !!(
    location?.pathname && location?.pathname.match('/account/congratulations/serum(/)?$')
  )

  const handlePageChange = path => (onPageChange ? onPageChange(path) : dispatch(closeMobileMenu()))

  //TODO add track event when are defined
  const handleStartSystemQuiz = () => {
    const path = '/quiz/start'
    handlePageChange(path)
    history.push(path)
  }
  const handleStartEyeQuiz = () => {
    const path = '/quiz/eye/start'
    handlePageChange(path)
    history.push(path)
  }
  const handleStartSerumQuiz = () => {
    const path = '/quiz/serum/start'
    handlePageChange(path)
    history.push(path)
  }

  const handleSkinCongratsPage = () => {
    const path = '/account/congratulations'
    handlePageChange(path)
    history.push(path)
  }
  const handleEyeCongratsPage = () => {
    const path = '/account/congratulations/eye'
    handlePageChange(path)
    history.push(path)
  }
  const handleSerumCongratsPage = () => {
    const path = '/account/congratulations/serum'
    handlePageChange(path)
    history.push(path)
  }

  const handleEyeShopPage = () => {
    const path = '/account/shop/eye'
    handlePageChange(path)
    history.push(path)
  }
  const handleSkinShopPage = () => {
    const path = '/account/shop/skin'
    handlePageChange(path)
    history.push(path)
  }
  const handleSerumShopPage = () => {
    const path = '/account/shop/serum'
    handlePageChange(path)
    history.push(path)
  }

  const handleUpdateSkinQuiz = () => {
    const path = '/quiz/start'
    handlePageChange(path)
    history.push(path) // TODO change to /personalize/skin when is available
  }
  const handleUpdateEyeQuiz = () => {
    const path = '/quiz/eye/start'
    handlePageChange(path)
    history.push(path) // TODO change to /personalize/eye when is available
  }
  const handleUpdateSerumQuiz = () => {
    const path = '/quiz/serum/start'
    handlePageChange(path)
    history.push(path)
  }

  const handleSubscriptionsManagement = () => {
    const path = '/account/subscriptions'
    handlePageChange(path)
    history.push(path)
  }

  const getImageByProductAndVariant = product => {
    if (variant === 'withoutImages') return null

    if (SYSTEM_PRODUCT === product)
      return variant === 'withIcons' ? (
        <ProvenIcon name="get-product-for-you-system" type="kit" iconSize="large" />
      ) : (
        <Box component="img" src={`${CDN_IMG}checkout-flow/cart-thumb-system.png`} sx={imageSx} />
      )

    if (EYE_CREAM_PRODUCT === product)
      return variant === 'withIcons' ? (
        <ProvenIcon name="get-product-for-you-eye" type="kit" iconSize="large" />
      ) : (
        <Box
          component="img"
          src={`${CDN_IMG}checkout-flow/cart-thumb-eye-cream-duo.png`}
          sx={imageSx}
        />
      )
    if (SERUM_PRODUCT === product)
      return variant === 'withIcons' ? (
        <ProvenIcon name="get-product-for-you-serum" type="kit" iconSize="large" />
      ) : (
        <Box component="img" src={`${CDN_IMG}checkout-flow/cart-thumb-serum.png`} sx={imageSx} />
      )
  }

  // TODO remove ThemeProvider from here when we refactor mobile menu. it's added since ProvenMobileMenu is at App.js level so dashboard uses ProvenMobileMenu
  return (
    <ThemeProvider theme={themeProven}>
      <Stack
        id="skin-quiz-menu"
        spacing={2}
        sx={{
          width: variant !== 'withImages' ? 'min-content' : undefined,
          minWidth: themeProven.spacing(35.125)
        }}
      >
        <ProductQuizMenu
          id="skincare-quiz"
          product={SYSTEM_PRODUCT}
          image={getImageByProductAndVariant(SYSTEM_PRODUCT)}
          title="3-Step Skincare Quiz"
          description="Personalized cleanser, day moisturizer & night cream"
          onStartQuiz={handleStartSystemQuiz}
          onCongratsPage={handleSkinCongratsPage}
          onShopPage={handleSkinShopPage}
          onUpdateQuiz={handleUpdateSkinQuiz}
          onSubscriptionsManagement={handleSubscriptionsManagement}
          hideButtonWithOnCongratsPageAction={isInSkinCongratsPage}
          onClose={handlePageChange}
          {...props}
        />
        {showProductDivider && (
          <Box sx={{ py: 2 }}>
            <Divider variant="fullWidth" sx={{ opacity: 0.2, color: 'gray.blueGray' }} />
          </Box>
        )}
        {appEdition !== APP_EDITION_SEPHORA && ( // hide Eye Cream and serum option for Sephora experience
          <>
            <ProductQuizMenu
              id="eye-quiz"
              product={EYE_CREAM_PRODUCT}
              image={getImageByProductAndVariant(EYE_CREAM_PRODUCT)}
              title="Eye Cream Duo Quiz"
              description="Personalized day eye cream & night eye cream"
              onStartQuiz={handleStartEyeQuiz}
              onCongratsPage={handleEyeCongratsPage}
              onShopPage={handleEyeShopPage}
              onUpdateQuiz={handleUpdateEyeQuiz}
              onSubscriptionsManagement={handleSubscriptionsManagement}
              hideButtonWithOnCongratsPageAction={isInEyeCongratsPage}
              onClose={handlePageChange}
              {...props}
            />
            <ProductQuizMenu
              id="serum-quiz"
              product={SERUM_PRODUCT}
              image={getImageByProductAndVariant(SERUM_PRODUCT)}
              title="Serum Quiz"
              description="Personalized serum"
              onStartQuiz={handleStartSerumQuiz}
              onCongratsPage={handleSerumCongratsPage}
              onShopPage={handleSerumShopPage}
              onUpdateQuiz={handleUpdateSerumQuiz}
              onSubscriptionsManagement={handleSubscriptionsManagement}
              hideButtonWithOnCongratsPageAction={isInSerumCongratsPage}
              onClose={handlePageChange}
              {...props}
            />
          </>
        )}
      </Stack>
    </ThemeProvider>
  )
}
SkinQuizMenu.defaultProps = {
  variant: 'withoutImages',
  showProductDivider: false
}

SkinQuizMenu.propTypes = {
  variant: propTypes.oneOf(['withoutImages', 'withIcons', 'withImages']),
  showProductDivider: propTypes.bool,
  imageBackgroundColor: propTypes.string,
  small: propTypes.bool,
  hideYotpo: propTypes.bool,
  onPageChange: propTypes.func
}
export default SkinQuizMenu
