import React, { useEffect, useState, useCallback } from 'react'
//import './styles.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import NotificationSnackbar from '../notification-snackbar'
import { closeNotification, deleteNotification } from '../../../../actions/app.actions'

function NotificationSnackbarWrapper({ children }) {
  const dispatch = useDispatch()
  const notifications = useSelector(store => store.app.notifications || [])
  const [displayed, setDisplayed] = useState([])
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [delayedNotificationsTimeouts, setDelayedNotificationsTimeouts] = useState([])

  const storeDisplayed = useCallback(
    id => {
      setDisplayed([...displayed, id])
    },
    [displayed]
  )

  const removeDisplayed = useCallback(
    id => {
      setDisplayed([...displayed.filter(key => id !== key)])
    },
    [displayed]
  )

  useEffect(() => {
    dispatch(closeNotification())
  }, [dispatch])

  useEffect(() => {
    const handleDelayedNotification = (enqueueSnackbarCall, timeouts, delay) => {
      let timeout
      const enqueueSnackbarAndRemoveTimeout = () => {
        enqueueSnackbarCall()
        //remove reached timeout from the state
        setDelayedNotificationsTimeouts(delayedNotificationsTimeouts => {
          return [...delayedNotificationsTimeouts].filter(t => t !== timeout)
        })
      }
      timeout = setTimeout(enqueueSnackbarAndRemoveTimeout, delay)
      timeouts.push(timeout)
      return timeouts
    }

    const timeouts = []
    notifications.forEach(({ key, text, strong, htmlContent, options = {}, dismissed = false }) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key)
        return
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return

      const enqueueSnackbarCall = () => {
        enqueueSnackbar('', {
          key,
          autoHideDuration: options?.autoHideDuration || 5000,
          content: () => (
            <NotificationSnackbar
              text={text}
              strong={strong}
              htmlContent={htmlContent}
              variant={options?.variant || 'success'}
              onClose={
                options.onClose
                  ? (event, reason, myKey) => options.onClose(event, reason, myKey)
                  : undefined
              }
            />
          ),
          anchorOrigin: options?.anchorOrigin || {
            vertical: 'top',
            horizontal: 'center'
          },
          onExited: (event, myKey) => {
            // remove this snackbar from redux store
            dispatch(deleteNotification(myKey))
            removeDisplayed(myKey)
          }
        })
        // keep track of snackbars that we've displayed
        storeDisplayed(key)
      }
      if (options?.delay) handleDelayedNotification(enqueueSnackbarCall, timeouts, options.delay)
      else enqueueSnackbarCall()
    })
    if (timeouts?.length)
      setDelayedNotificationsTimeouts(delayedNotificationsTimeouts =>
        delayedNotificationsTimeouts.concat(timeouts)
      )
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch, displayed, setDisplayed])

  useEffect(() => {
    return () => delayedNotificationsTimeouts.forEach(t => clearTimeout(t))
  }, [])

  return <>{children}</>
}

export default NotificationSnackbarWrapper
