import React from 'react'
import ProvenIcon from '../proven-icon'
import propTypes from 'prop-types'
import { Stack, Box } from '@mui/material'

function ProvenIconWithText({ iconSize, justifyContent, ...props }) {
  const { children, gap } = props
  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: justifyContent ? justifyContent : 'flex-start',
        alignItems: 'center',
        gap
      }}
    >
      <ProvenIcon {...props} size={iconSize} />
      <Box>{children}</Box>
    </Stack>
  )
}

ProvenIconWithText.defaultProps = {
  iconSize: 'medium',
  gap: 2
}
ProvenIconWithText.propTypes = {
  type: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
  color: propTypes.string,
  hoverColor: propTypes.string,
  gap: propTypes.number,
  justifyContent: propTypes.string,
  iconSize: propTypes.oneOf(['large', 'medium', 'small']),
  children: propTypes.node.isRequired
}

export default ProvenIconWithText
