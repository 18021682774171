import React, { useMemo } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import Slider from 'react-slick'
import ProvenIcon from '../../proven-icon'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useSelector } from 'react-redux'
import { appEditionSelector } from '../../../../utils/selectors'
import { APP_EDITION_SEPHORA } from '../../../../constants/constants'

const DEFAULT_BENEFITS = [
  {
    icon: 'customer-care',
    title: 'Priority Support',
    text: 'PROVEN members get priority support for all their questions'
  },
  {
    icon: 'gift',
    title: 'Surprise Gifts',
    text: 'Special treats that help you make the most out of your routine'
  },
  {
    icon: 'reformulation-guarantee',
    title: 'Reformulation',
    text: 'If something is not working for you, we will reformulate'
  },
  {
    icon: 'event',
    title: 'Exclusive Events',
    text: 'Invite-only webinars and events with PROVEN dermatologists '
  }
]

const SEPHORA_BENEFITS = [
  {
    icon: 'customer-care',
    title: 'Priority Support',
    text: 'PROVEN members get priority support for all their questions'
  },
  {
    icon: 'reformulation-guarantee',
    title: 'Reformulation',
    text: 'If something is not working for you, we will reformulate'
  },
  {
    icon: 'event',
    title: 'Exclusive Events',
    text: 'Invite-only webinars and events with PROVEN dermatologists '
  }
]

function Benefits() {
  const isLgUp = useMediaQuery(theme => theme.breakpoints.up('lg'))
  const appEdition = useSelector(appEditionSelector)

  const benefits = useMemo(
    () => (appEdition === APP_EDITION_SEPHORA ? SEPHORA_BENEFITS : DEFAULT_BENEFITS),
    [appEdition]
  )
  const defaultSliderSettings = {
    speed: 500,
    slidesToShow: benefits.length,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    infinite: true,
    centerPadding: '30px',
    responsive: [
      {
        breakpoint: 1281,
        settings: {
          slidesToShow: 3,
          centerPadding: 0
        }
      },
      {
        breakpoint: 721,
        settings: {
          slidesToShow: 1,
          centerPadding: '70px'
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerPadding: '70px'
        }
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          centerPadding: '70px'
        }
      }
    ]
  }

  const renderBenefits = () =>
    benefits.map((ben, i) => (
      <Box
        key={`ing${i}`}
        sx={{
          maxWidth: '220px',
          '&:nth-child(n+2)': {
            ml: { xs: 1, md: 4 }
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%'
          }}
        >
          <ProvenIcon iconSize="2rem" type="kit" name={ben.icon} />
        </Box>
        <Typography
          variant="title2"
          sx={{
            display: 'block',
            mt: [1],
            mb: [1],
            textAlign: 'center'
          }}
        >
          {ben.title}
        </Typography>
        <Typography
          variant="footnote"
          sx={{
            display: 'block',
            mt: [1],
            mb: [1],
            textAlign: 'center'
          }}
        >
          {ben.text}
        </Typography>
      </Box>
    ))

  const renderSlider = () => (
    <Box
      sx={{
        maskImage: 'linear-gradient(to right, transparent -14%, black 35%);',
        '& .slick-slide': {
          textAlign: 'center'
        }
      }}
    >
      <Box
        sx={{
          maskImage: 'linear-gradient(to left, transparent -14%, black 35%);'
        }}
      >
        <Slider {...defaultSliderSettings}>{renderBenefits()}</Slider>
      </Box>
    </Box>
  )

  const renderRow = () => (
    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>{renderBenefits()}</Box>
  )

  return (
    <Stack sx={{ position: 'relative', margin: '0 auto' }}>
      {isLgUp ? renderRow() : renderSlider()}
    </Stack>
  )
}

Benefits.propTypes = {}

export default Benefits
