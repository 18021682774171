import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import propTypes from 'prop-types'
import './styles.scss'

function ReviewsYotpoInner({ title, yotpo_product }) {
  const location = useLocation()

  useEffect(() => {
    setTimeout(() => {
      const { hash, pathname } = location
      if (hash === '#submit' && pathname.includes('/reviews')) {
        const reviewButtons = document.getElementsByClassName('write-review-button')
        if (reviewButtons?.length > 0) {
          const reviewButton = reviewButtons[0]
          reviewButton.click()
        }
      }
    }, 500)
  }, [location])

  return (
    <section data-testid="reviews-yotpo-inner-testid" styleName="reviews-yotpo">
      {title && <div styleName="title">{title}</div>}

      {yotpo_product && yotpo_product.product_id && (
        <div
          className="yotpo yotpo-main-widget"
          data-product-id={yotpo_product.product_id}
          // data-price="119.00"
          // data-currency="USD"
          data-name={yotpo_product.name}
          data-url={yotpo_product.url || ''}
          data-image-url={yotpo_product.image_path || ''}
        ></div>
      )}
    </section>
  )
}

ReviewsYotpoInner.propTypes = {
  title: propTypes.string,
  show_stars: propTypes.bool,
  yotpo_product: propTypes.shape({
    name: propTypes.string,
    product_id: propTypes.string,
    url: propTypes.string,
    image_path: propTypes.string
  })
}

ReviewsYotpoInner.defaultProps = {
  yotpo_product: {
    name: 'Personalized Skincare System',
    product_id: '823100',
    url: 'https://www.provenskincare.com/personalized-subscription/'
  }
}

export default ReviewsYotpoInner
