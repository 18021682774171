import React, { useState } from 'react'
import { Icon, SvgIcon } from '@mui/material'

import {
  DEFAULT_FIXED_SIZE_BY_TYPE,
  ICON_SIZES,
  ICON_TYPES_IN_CDN,
  TYPE_DASHBOARD,
  TYPE_KIT,
  TYPE_SOCIAL,
  TYPE_SYSTEM,
  TYPE_TAB,
  TYPE_TAG,
  VIEW_BOX_BY_ICON_TYPE
} from '../../../constants/icons'
import propTypes from 'prop-types'
import { CDN_IMG } from '../../../constants/endpoints'

const ProvenIcon = ({ size, type, name, color, hoverColor, sx, hoverName, ...props }) => {
  const sxToUse = sx ? { ...sx } : {}
  const sizeToUse = size ? size : DEFAULT_FIXED_SIZE_BY_TYPE[type]
  const isFixedSize = ICON_SIZES.some(size => size === sizeToUse)
  const fontSize = isFixedSize ? sizeToUse : undefined

  const [isImgHover, setIsImgHover] = useState(false)

  const isIconInCDN = ICON_TYPES_IN_CDN.some(t => t === type)

  if (!isFixedSize) {
    sxToUse.fontSize = sizeToUse + (isIconInCDN ? '!important' : '')
    if (isIconInCDN) {
      sxToUse.width = `${sizeToUse} !important`
      sxToUse.height = `${sizeToUse} !important`
      sxToUse.lineHeight = '100%'
    }
  }

  const getCDNUrlByTypeAndName = (type, name, iconNameOnHover) =>
    type === TYPE_SOCIAL || type === TYPE_TAG
      ? `${CDN_IMG}icons/${iconNameOnHover && isImgHover ? iconNameOnHover : name}.svg`
      : `${CDN_IMG}icons/icon-${type}-${iconNameOnHover && isImgHover ? iconNameOnHover : name}.svg`

  if (isIconInCDN) {
    return (
      <Icon
        fontSize={fontSize}
        sx={sxToUse}
        onMouseEnter={hoverName && (() => setIsImgHover(true))}
        onMouseLeave={hoverName && (() => setIsImgHover(false))}
        {...props}
      >
        <img src={getCDNUrlByTypeAndName(type, name, hoverName)} height="100%" width="100%" />
      </Icon>
    )
  }

  if (color) sxToUse.color = color
  if (hoverColor) sxToUse['&:hover'] = { color: hoverColor }

  return (
    <SvgIcon
      {...props}
      sx={sxToUse}
      fontSize={fontSize}
      component={require(`./svgs/icon-${type}-${name}.svg`).ReactComponent}
      viewBox={VIEW_BOX_BY_ICON_TYPE[type]}
    ></SvgIcon>
  )
}

ProvenIcon.defaultProps = {
  type: TYPE_SYSTEM,
  name: 'alert'
}

ProvenIcon.propTypes = {
  type: propTypes.oneOf([TYPE_KIT, TYPE_SYSTEM, TYPE_TAB, TYPE_DASHBOARD, TYPE_SOCIAL]).isRequired,
  name: propTypes.string.isRequired,
  size: propTypes.string,
  onClick: propTypes.func,
  color: propTypes.string,
  hoverColor: propTypes.string,
  hoverName: propTypes.string
}

export default ProvenIcon
