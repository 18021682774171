import React from 'react'
import { SvgIcon } from '@mui/material'
import propTypes from 'prop-types'
import {
  LOGO_ONE_LINE,
  LOGO_DOUBLE_LINE,
  LOGO_SYMBOL,
  VIEW_BOX_BY_LOGO,
  LOGO_DOUBLE_LINE_BIG,
  SVG_NAME_BY_LOGO,
  LOGO_QUSTION,
  LOGO_SEPHORA,
  LOGO_SEPHORA_DOUBLE_LINE,
  LOGO_SKIN_GENOME_QUIZ
} from '../../../constants/logos'

import { createProvenTheme } from '../../../styles/theme-proven'

const theme = createProvenTheme()

const ProvenLogo = props => {
  const { variant, onClick, inWhite, id } = props

  const sx = {
    ...theme.logos[variant],
    color: inWhite ? theme.palette.gray.white : theme.palette.gray.elysian,
    ...(onClick ? { cursor: 'pointer', pointerEvents: 'auto' } : {}),
    ...props.sx
  }
  const svgName = SVG_NAME_BY_LOGO[variant]
  return (
    <SvgIcon
      id={id}
      onClick={onClick}
      sx={sx}
      component={require(`./svgs/${svgName}.svg`).ReactComponent}
      viewBox={VIEW_BOX_BY_LOGO[variant]}
    ></SvgIcon>
  )
}

ProvenLogo.defaultProps = {
  variant: LOGO_ONE_LINE,
  inWhite: false
}

ProvenLogo.propTypes = {
  variant: propTypes.oneOf([
    LOGO_SYMBOL,
    LOGO_DOUBLE_LINE,
    LOGO_DOUBLE_LINE_BIG,
    LOGO_ONE_LINE,
    LOGO_QUSTION,
    LOGO_SEPHORA,
    LOGO_SEPHORA_DOUBLE_LINE,
    LOGO_SKIN_GENOME_QUIZ
  ]),
  inWhite: propTypes.bool,
  color: propTypes.oneOf(['primary', 'secondary']),
  onClick: propTypes.func,
  sx: propTypes.object
}

export default ProvenLogo
