import React from 'react'
import { InputAdornment, TextField as MuiTextField, styled } from '@mui/material'
import ProvenErrorMessage from '../proven-error-message'
import ProvenIcon from '../proven-icon'

const StyledTextField = styled(MuiTextField)(() => ({
  '& .MuiInputBase-inputAdornedStart': {
    paddingLeft: 0
  }
}))

function ProvenTextField({
  value,
  onChange,
  disabled,
  label,
  error,
  isThereAnUnknownError,
  placeholder,
  color,
  iconType,
  iconName,
  maxLength,
  ...props
}) {
  const inputProps = { sx: {} }
  const sx = {}

  if (iconType && iconName) {
    inputProps.startAdornment = (
      <InputAdornment position="start">
        <ProvenIcon type={iconType} name={iconName} />
      </InputAdornment>
    )
  }
  if (color) inputProps.sx.color = color

  return (
    <StyledTextField
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
      label={label}
      color={error || isThereAnUnknownError ? 'error' : 'primary'}
      error={error || isThereAnUnknownError}
      helperText={
        (error || typeof isThereAnUnknownError === 'string') && (
          <ProvenErrorMessage
            message={error || isThereAnUnknownError}
            data-cy="render-input-error"
          />
        )
      }
      FormHelperTextProps={{ sx: { mx: 0 } }}
      InputProps={inputProps}
      inputProps={maxLength ? { maxLength } : undefined}
      {...props}
    />
  )
}
export default ProvenTextField
