import React, { useEffect, useState } from 'react'
import { Box, Button, TextField } from '@mui/material'
import Typography from '@mui/material/Typography'
import { patchStoreFieldsUser } from '../../../../../actions/account.actions'
import { useDispatch, useSelector } from 'react-redux'
import { identifyEmptyUser, trackEvent } from '../../../../../utils/analytics'
import { variantSelector } from '../../../../../utils/selectors'
import { useAuthContext } from '../../../../../providers/AuthProvider'

const DATA_CY_PREFIX = 'sephora-in-store-ba-name-container'
/* when check if a user exist, if the status is CONFIRMED mean is a full user */
const FULL_USER_STATUS = 'CONFIRMED'
const RESET_REQUIRED = 'RESET_REQUIRED'
const STATE_FOR_EXISTING_USER = [FULL_USER_STATUS, RESET_REQUIRED]

function EnterBaNameContainer({
  onCompleteWithMagicLink,
  onCompleteEmptyUser,
  email,
  storeId,
  existingUserStatus
}) {
  const dispatch = useDispatch()
  const [baName, setBaName] = useState('')
  const [isValidBaName, setIsValidBaName] = useState(false)
  const [isProcess, setIsProcess] = useState(false)
  const variant = useSelector(variantSelector)
  const authContext = useAuthContext()
  const { startPasswordlessAuth } = authContext
  const ctaText = 'SUBMIT'

  useEffect(() => {
    if (baName?.length > 0) {
      setIsValidBaName(true)
    } else {
      setIsValidBaName(false)
    }
  }, [baName])

  const handleBaNameChange = e => {
    const myBaName = e.currentTarget.value
    setBaName(myBaName)
  }

  const handleSubmit = async () => {
    setIsProcess(true)
    try {
      // update baName in BE
      const payload = { sephoraAdvisorName: baName, sephoraStoreId: storeId }
      const { data } = await dispatch(patchStoreFieldsUser(email, payload))
      // identify user
      const user = data?.result?.user
      await identifyEmptyUser(user)
    } catch (e) {
      console.error('ba name ', e)
    }

    // track CTA click
    trackEvent('submit', { CTA: ctaText, variant, questionType: 'advisor' })

    await onComplete()
    setIsProcess(false)
  }

  const onKeyPress = event => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }

  /**
   * this function handle how to call onCompleteWithMagicLink
   * @returns {Promise<void>}
   */
  const handleOnCompleteWithMagicLink = async () => {
    // 1. Send magic link
    // this.props.clearPasswordlessAuth()
    const res = await startPasswordlessAuth(email)
    if (res === false) {
      onCompleteWithMagicLink(email, baName, false, true)
      return
    }
    // // 2. Render the overlay drawer for Magic Link
    onCompleteWithMagicLink(email, baName, true, false)
  }

  /**
   * this funciton is the last step after send all identify calls
   * @returns {JSX.Element}
   */
  const onComplete = async () => {
    // check if the user exist
    if (STATE_FOR_EXISTING_USER.includes(existingUserStatus)) {
      // if the email is from a FULL USER
      // open slide dialog with magic link
      await handleOnCompleteWithMagicLink()
    } else {
      // new empty user
      if (onCompleteEmptyUser) {
        onCompleteEmptyUser(baName)
      }
    }
  }

  /**
   * click on skip button
   * @param e
   * @returns {Promise<void>}
   */
  const handleSkip = async e => {
    setIsProcess(true)

    await onComplete()
    setIsProcess(false)
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        mx: 2,
        top: '50%',
        transform: 'translateY(20%)'
      }}
    >
      <Box
        sx={{
          px: 2,
          pt: 2,
          border: 1,
          borderRadius: 1,
          borderColor: 'gray.lightGray',
          backgroundColor: 'gray.white'
        }}
      >
        <Typography
          variant="h4"
          sx={{
            mb: 2
          }}
        >
          Did a Sephora Beauty Advisor help you? If so, enter their name:
        </Typography>
        <TextField
          fullWidth
          onKeyPress={onKeyPress}
          onChange={handleBaNameChange}
          name="baName"
          placeholder="Beauty Advisor Name (optional)"
          value={baName}
          sx={{
            mb: 1
          }}
        />
        <Button
          onClick={handleSubmit}
          data-cy={`${DATA_CY_PREFIX}-email-me-my-quiz`}
          data-testid={`${DATA_CY_PREFIX}-email-me-my-quiz`}
          variant="contained"
          color="secondary"
          fullWidth
          sx={{ mb: 2 }}
          type="submit"
          disabled={!isValidBaName || isProcess}
        >
          {ctaText}
        </Button>
        <Button
          onClick={handleSkip}
          data-cy={`${DATA_CY_PREFIX}-email-me-my-quiz`}
          data-testid={`${DATA_CY_PREFIX}-email-me-my-quiz`}
          variant="outlined"
          color="primary"
          fullWidth
          sx={{ mb: 2 }}
          disabled={isProcess}
        >
          SKIP
        </Button>
      </Box>
    </Box>
  )
}

EnterBaNameContainer.propTypes = {}

export default EnterBaNameContainer
