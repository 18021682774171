import React from 'react'
import propTypes from 'prop-types'

import { createProvenTheme } from '../../../styles/theme-proven'
import ProvenShape from '../proven-shape'

const theme = createProvenTheme()
const SVG_NAME = 'hex-polygon'
const VIEW_BOX = '0 0 170 196'

const UNIT = 'rem'

const BASE_SIZE = {
  width: 10.625,
  height: 12.25
}

function ProvenHexShape({ color, scale, zIndex, children, opacity }) {
  return (
    <ProvenShape
      color={color ? color : theme.palette.primary.current}
      opacity={opacity}
      baseWidth={BASE_SIZE.width}
      baseHeight={BASE_SIZE.height}
      unit={UNIT}
      viewBox={VIEW_BOX}
      name={SVG_NAME}
      scale={scale}
      zIndex={zIndex}
    >
      {children}
    </ProvenShape>
  )
}

ProvenHexShape.defaultProps = {
  scale: 1,
  zIndex: 0
}

ProvenHexShape.propTypes = {
  scale: propTypes.number,
  color: propTypes.string,
  children: propTypes.node,
  opacity: propTypes.number
}

export default ProvenHexShape
